import * as React from 'react'

import { StaticImage } from 'gatsby-plugin-image'
import { isMobile } from 'react-device-detect'

import CTA from '../../../components/organisms/CTA'
import CasesRelational from '../../../components/organisms/CasesRelational'
import Seo from '../../../helpers/seo'
import Layout from '../../../templates/layout'
import Content from './content'
import ikeaJson from './ikea.json'

const Ikea = () => {
  const { relational } = ikeaJson

  const Banner = () => {
    return (
      <section className="content-banner">
        {isMobile ? (
          <StaticImage
            src="../../../assets/images/cases-content/banner-ikea.webp"
            alt="Bluementor"
            placeholder="blurred"
            loading="eager"
            width={920}
            objectFit="cover"
            layout="fixed"
            objectPosition="center"
            quality={95}
            formats={['auto', 'webp', 'avif']}
          />
        ) : (
          <StaticImage
            src="../../../assets/images/cases-content/banner-ikea.webp"
            alt="Bluementor"
            placeholder="blurred"
            loading="eager"
            width={1920}
            objectFit="cover"
            layout="fixed"
            objectPosition="center"
            quality={95}
            formats={['auto', 'webp', 'avif']}
          />
        )}
      </section>
    )
  }

  return (
    <Layout page="Ikea">
      <Banner />
      <Content />
      <CTA />
      <CasesRelational relationalData={relational} itsRelationalCase />
    </Layout>
  )
}
export const Head = () => <Seo title="Ikea" />

export default Ikea
